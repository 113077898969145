var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"remark-exam-user-list-layout"},[_c('Breadcrumb',[_c('BreadcrumbItem',[_vm._v("考试管理")]),_c('BreadcrumbItem',[_vm._v("批阅")]),_c('BreadcrumbItem',[_vm._v("题目列表")])],1),_c('div',{staticClass:"wrapper"},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.examList},scopedSlots:_vm._u([{key:"record-name",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.sectionName)+" - "+_vm._s(row.questionOrder))])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('JYIcon',{staticClass:"action-icon",attrs:{"href":"#icon-piyue"},on:{"click":function($event){return _vm.handleEnterDetail(row)}}})]}},{key:"status",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(row.status === 4)?_c('JYStatus',{key:index,attrs:{"label":"批阅中","color":"orange"}}):_vm._e(),(row.status === 5)?_c('JYStatus',{key:index,attrs:{"label":"已批阅","color":"green"}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }